import { PolicyPage } from "@dangerfarms/website-core";
import { graphql } from "gatsby";
import React from "react";

// There's not much gain to be had from maintaining prop type definitions for
// this component, because they're passed from Gatsby, it's not an interface
// we'll ever need to use.
//
/* eslint-disable react/prop-types */
const PrivacyPage = ({ data }) => {
  // Required check for no data being returned
  const prismicPolicy = data.prismic.allPolicys.edges.slice(0, 1).pop();
  if (!prismicPolicy) {
    return null;
  }
  const prismic = prismicPolicy.node;

  return <PolicyPage pageTitle="Privacy Policy" prismic={prismic} />;
};
/* eslint-enable */

export const query = graphql`
  query privacyPolicyQuery {
    prismic {
      allPolicys(uid: "privacy") {
        edges {
          node {
            description
            policy_heading
            policy_date
            policy_intro
            policy_content
          }
        }
      }
    }
  }
`;

export default PrivacyPage;
